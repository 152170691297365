import { ApiService } from '../../../common/services/api/api.service';
import { Injectable } from '@angular/core';
import { Observable, map, of, switchMap } from 'rxjs';
import { VisitorEvent } from '../../shared/models/visitor.event';
import { EventTimeService } from './event.time.service';
import { CalendarQueryCommand } from '../../shared/models/calendar.action.command';
import { isEmptyArray } from 'src/app/common/utils/object.extensions';
import { CreateEventCommand } from '../../shared/models/create.event.command';
import { ActivatedRoute } from '@angular/router';
import { ErrorAlertService } from 'src/app/common/services/error.alert/error.alert.service';
import { IAppState } from '../../shared/ngrx.stores/frontend.settings/states';
import { Store } from '@ngrx/store';
import * as EventQueryCommandActions from 'src/app/modules/event-calendar/ngrx.stores/event.query.command/actions';

@Injectable({
  providedIn: 'root',
})
export class VisitorEventService {
  constructor(
    private _apiService: ApiService,
    private _eventTimeService: EventTimeService,
    private _errorAlertService: ErrorAlertService,
    private _store: Store<IAppState>
  ) {
  }

  public getEvents(
    hubName: string,
    paramObj: CalendarQueryCommand
  ): Observable<VisitorEvent[]> {
    return this._apiService.get(`visitors/${hubName}/events`, paramObj).pipe(
      map((events) => {
        events.forEach((event) => {
          this._eventTimeService.processEventTimes(event);
        });
        return events;
      })
    );
  }

  public getLocationFilter(
    hubName: string
  ): Observable<{locality: string}[]> {
    return this._apiService.get(`visitors/${hubName}/events/location-filter`);
  }

  public buildFilterEventsByDateCommandFromUrlQuery(
    activatedRoute: ActivatedRoute
  ): Observable<CalendarQueryCommand> {
    return activatedRoute.queryParams.pipe(
      switchMap((params) => {
        console.log('params', params);

        let {date, start, end} = params;

        if (!date && !start && !end) {
          if (['start', 'end', 'date'].any(x => window.location.search.includes(x))) {
            const searchParams = new URLSearchParams(window.location.search.split('?')[1]);
            for (const [key, value] of searchParams) {
              if (key === 'date') date = value;
              if (key === 'start') start = value;
              if (key === 'end') end = value;
            }
            console.log(`date: ${date}, start: ${start}, end: ${end}`);
          }
        }

        let from = new Date(new Date().setHours(0, 0, 0, 0));
        let to = new Date(new Date().setHours(24, 0, 0, 0));
        const command = new CalendarQueryCommand();

        if (!date && !start && !end) {
          // for client site, we probably not able to get params from activatedRoute
          // so we need to get params from url
          const urlParams = new URLSearchParams(window.location.search);
          start = urlParams.get('start');
          end = urlParams.get('end');
          date = urlParams.get('date');
          console.log(`params from URL: start: ${start}, end: ${end}, date: ${date}`);
        }

        if (!date && !start && !end) {
          return of(null);
        }

        if (date) {
          switch (date) {
            case 'all':
              break;
            case 'today':
              command.from = from;
              command.to = to;
              break;
            case 'tomorrow':
              command.from = new Date(from.setDate(from.getDate() + 1));
              command.to = new Date(to.setDate(to.getDate() + 1));
              break;
            case 'this-week':
              command.from = new Date(
                from.setDate(from.getDate() + (from.getDay() - 2))
              );
              command.to = new Date(
                to.setDate(to.getDate() + (7 - to.getDay() + 1))
              );
              break;
            case 'this-weekend':
              command.from = new Date(
                from.setDate(from.getDate() + (7 - from.getDay() - 2))
              );
              command.to = new Date(
                to.setDate(to.getDate() + (7 - to.getDay() + 1))
              );
              break;
            default:
              this._errorAlertService.showErrorAlert(
                'Invalid date, only "today", "tomorrow", "this-week" and "this-weekend" are supported.'
              );
          }
        } else if (start && end) {
          const startDate = new Date(start).setDate(
            new Date(start).getDate() + 1
          );
          const endDate = new Date(end).setDate(new Date(end).getDate() + 1);
          command.from = new Date(new Date(startDate).setHours(0, 0, 0, 0));
          command.to = new Date(new Date(endDate).setHours(24, 0, 0, 0));
        } else {
          this._errorAlertService.showErrorAlert(
            'Invalid date, please specify start date and end date.'
          );
        }

        return of(command);
      })
    );
  }

  public buildFilterEventsByCustomCategoryCommandFromUrlQuery(
    activatedRoute: ActivatedRoute
  ): Observable<CalendarQueryCommand> {
    return activatedRoute.queryParams.pipe(
      switchMap((params) => {
        let customEvents = params['ig-custom-events'];

        if (window.location.search.includes('ig-custom-events')) {
          const searchParams = new URLSearchParams(window.location.search.split('?')[1]);
          console.log('searchParams', searchParams);
          for (const [key, value] of searchParams) {
            if (key === 'ig-custom-events') customEvents = value;
          }
          console.log(`customEvents in custom/fav event category: ${customEvents}`);
        }

        if (!customEvents) {
          return of(null);
        }

        const command = new CalendarQueryCommand();
        command.favoriteEventBtnId = customEvents;

        return of(command);
      })
    );
  }

  public createEvent(hubName, command: CreateEventCommand) {
    // let formData = new FormData();

    // for (let property of Object.keys(command)) {
    //   if (command[property]) {
    //     formData.append(property, command[property]);
    //   }
    // }

    // formData.set('address', JSON.stringify(command.address));
    // if (!!command.image) {
    //   formData.set('image', command.image, command.image.name);
    // } else {
    //   formData.set('image', null);
    // }

    return this._apiService.post(`visitors/${hubName}/events`, command);
  }

  public isNormalTicketUri(event: VisitorEvent): boolean {
    if (!event) return false;
    return (
      event.ticketUri &&
      (isEmptyArray(event.ticketInfo) ||
        event.ticketUri !== event.ticketInfo[0].link)
    );
  }

  public venueName(event: VisitorEvent): string {
    if (!event) return '';
    return event.venue || event.venueInfo?.name || event.owner?.name;
  }

  public processedTicketUri(ticketUri: string): string {
    let noSchema = ticketUri.replace('https://', '');
    noSchema = noSchema.replace('http://', '');
    noSchema = noSchema.replace('www.', '');
    return noSchema.split('/')[0];
  }

  public openTicketUrl(link: string) {
    window.open(link.startsWith('http') ? link : `https://${link}`, '_blank');
  }

  public visitWebsite(event: VisitorEvent) {
    if (event.eventLink || event.hostLink) {
      window.open(event.eventLink || event.hostLink, '_blank');
    }
  }

  getDestinationEventFeeds(hubName: string): Observable<any> {
    return this._apiService
      .get(`visitors/${hubName}/events/events-for-travel-buddy`)
      .pipe(
        map((events) => {
          // move the processing to ai.buddy to speed up
          // events.forEach((event) => {
          //   this._eventTimeService.processEventTimes(event);
          // });
          return events;
        })
      );
  }
}
