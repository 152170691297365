import {
  ImageLinkFileSelectorComponent
} from 'src/app/common/components/image.link.file.selector/image.link.file.selector.component';
import { ButtonTriggerEvent } from './../../../../common/components/button/button.component';
import { ImageSelectorComponent } from 'src/app/common/components/image.selector/image.selector.component';
import { finalize, switchMap } from 'rxjs/operators';
import { ImageService } from 'src/app/common/services/image/image.service';
import { VisitorEventService } from './../../services/visitor.event.service';
import { EventTimeService } from './../../services/event.time.service';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  Component,
  ViewEncapsulation,
  AfterViewInit,
  ViewChild,
} from '@angular/core';
import { Observable, tap, catchError, of, takeUntil } from 'rxjs';
import {
  IAppState,
  IFrontendSettings,
} from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';
import { BaseComponent } from 'src/app/common/components/base.component';
import { select, Store } from '@ngrx/store';
import { frontendSettingsSelector } from 'src/app/modules/shared/ngrx.stores/frontend.settings/selectors';
import { ActivatedRoute } from '@angular/router';
import { CreateEventCommand } from 'src/app/modules/shared/models/create.event.command';
import { ErrorAlertService } from 'src/app/common/services/error.alert/error.alert.service';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalService } from 'src/app/common/services/modal/modal.service';

@Component({
  selector: 'ig-create-event',
  templateUrl: './create.event.component.html',
  styleUrls: ['./create.event.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class CreateEventComponent extends BaseComponent {
  form: FormGroup;
  isCssReady = false;

  @ViewChild('imgUploader', {static: false})
  imgUploader: ImageLinkFileSelectorComponent;

  constructor(
    public activatedRoute: ActivatedRoute,
    public eventService: VisitorEventService,
    public timeService: EventTimeService,
    public imgService: ImageService,
    public modalService: ModalService,
    public dialogRef: MatDialogRef<CreateEventComponent>,
    private _fb: FormBuilder,
    private _errorAlertService: ErrorAlertService,
    private _store: Store<IAppState>
  ) {
    super({activatedRoute});

    this.resetForm();

    // handle browser back button click, close the view and reload the page
    window.addEventListener('popstate', this.hashChanged.bind(this));

    // as this modal is ShadowDom, we need to inject the styles into it
    dialogRef.afterOpened().subscribe(() => {
      modalService.injectStyle('.cdk-overlay-container ig-create-event', [], () => {
        this.isCssReady = true;
      });
    });
  }

  public get isFormInValid() {
    return this.form.invalid || !this.imgUploader?.isValid;
  }

  onlineEventChange($event) {
    this.form.get('isOnlineEvent').setValue($event.checked);
    if ($event.checked) {
      // this.form.get('address').removeValidators([Validators.required]);
      this.form.get('address').setValue('Online Event');
      this.form.get('isHybridEvent').setValue(false);
    } else {
      // this.form.get('address').setValidators([Validators.required]);
      this.form.get('address').setValue('');
    }
  }

  hybridChange($event) {
    this.form.get('isHybridEvent').setValue($event.checked);
    if ($event.checked) {
      this.form.get('isOnlineEvent').setValue(false);
      const address = this.form.get('address').value;
      if (address === 'Online Event') {
        this.form.get('address').setValue('');
      }
    }
  }

  public close() {
    this.dialogRef.close();
  }

  imgSelected(imgFile: File) {
    this.form.get('imgFile').setValue(imgFile);
  }

  async submit(event: ButtonTriggerEvent) {
    const address = this.form.get('address').value;
    const isOnline = this.form.get('isOnlineEvent').value;
    if ((typeof address === 'string' || !address) && !isOnline) {
      this._errorAlertService.showErrorAlert(
        'Please type the physical address and select one from the dropdown'
      );
      event.immediateComplete();
      return;
    }

    if (this.form.invalid || !this.imgUploader.isValid) {
      event.complete();
      return;
    }

    const times = this.form.get('timeRanges')['controls'];
    const eventTimes = [];
    for (const time of times) {
      const start = time.get('startTime').value;
      start &&
      eventTimes.push({
        startTime: start,
        endTime: time.get('endTime').value,
      });
    }
    this.form.get('eventTimes').setValue(eventTimes);

    this.form
      .get('timezone')
      .setValue(
        this.timeService.getTZByFormatter(
          this.frontendSettings.customSettings.timezoneFormatter
        )
      );

    this.imgUploader
      .uploadImg()
      .pipe(
        takeUntil(this.isDestroyed),
        finalize(event.complete),
        switchMap((data) => {
          this.form.get('imageLink').setValue(data.url);
          this.form.get('imgFile').setValue(null);
          return this.eventService.createEvent(
            this.hubName,
            <CreateEventCommand>this.form.value
          );
        }),
        tap(() => {
          this.resetForm();
          event.success();
        }),
        catchError((err) => {
          this._errorAlertService.showErrorAlert(err.message);
          return of(null);
        })
      )
      .subscribe();
  }

  private resetForm() {
    this.form = this._fb.group({
      name: [null, [Validators.required]],
      description: [null, []],
      category: [null, []],
      timeRanges: this._fb.array([
        this._fb.group({
          startTime: [null, [Validators.required]],
          endTime: [null, []],
        }),
      ]),
      eventTimes: [],
      // startTime: [null, [Validators.required]],
      // endTime: [],
      address: [null, [Validators.required]],
      host: [null, [Validators.required]],
      venue: [null, []],
      timezone: [null, []],
      hostLink: [null, []],
      eventLink: [null, []],
      organizerEmail: [null, []],
      ticketUri: [null, []],
      imageLink: [null, []],
      isHybridEvent: [false, []],
      isOnlineEvent: [false, []],
      imgFile: [null, []],
    });

    this.imgUploader?.clear();
  }

  /**
   * handle browser back button click, close the view
   * only close the page is not enough, it will always have other loading issues for the script embedding solution
   * so force reload the page for now
   * */
  // @HostListener('window:popstate', ['$event'])
  private hashChanged($event: Event) {
    $event.preventDefault();
    let url = window.location.href.split('imgoing-event')[0];
    url = url.endsWith('?') ? url.substring(0, url.length - 1) : url;
    window.location.href = url;
    this.close();
  }
}
