import { MatMenuTrigger } from '@angular/material/menu';
import { VisitorPlaceService } from 'src/app/modules/event-calendar/services/visitor.place.service';
import { VisitorPlace } from './../../../shared/models/visitor.place';
import { Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable, delay, finalize, tap, timer } from 'rxjs';
import { BaseComponent } from 'src/app/common/components/base.component';
import { ErrorAlertService } from 'src/app/common/services/error.alert/error.alert.service';
import { SeoService } from 'src/app/common/services/seo.service/seo.service';
import { IPlaceDetailsModalData } from './place.details.modal.provider';
import { ConfigService } from 'src/app/common/services/config/config.service';
import { MapMarker } from '@angular/google-maps';
import { ModalService } from 'src/app/common/services/modal/modal.service';
import { FancyPlaceDetailsModalComponent } from './fancy.place.details.modal/fancy.place.details.modal.component';
import {
  clone,
  isEmptyArray,
  isNullOrUndefined,
} from 'src/app/common/utils/object.extensions';
import { VisitorEvent } from 'src/app/modules/shared/models/visitor.event';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CalendarQueryCommand } from 'src/app/modules/shared/models/calendar.action.command';
import {
  IAppState,
  IFrontendSettings,
} from 'src/app/modules/shared/ngrx.stores/frontend.settings/states';
import { Store, select } from '@ngrx/store';
import { frontendSettingsSelector } from 'src/app/modules/shared/ngrx.stores/frontend.settings/selectors';
import { IMapMarkerWithCustomData } from 'src/app/modules/shared/models/place.marker';

export class PlaceDetailsBaseComponent extends BaseComponent {
  isOpened = false;
  isLoading = false;
  place: VisitorPlace;
  hasServes = false;
  hasOtherServices = false;
  hasPaymentOptions = false;
  hasParkingOptions = false;
  hasAccessibilityOptions = false;
  imgIndex = 0;
  currentImg: string;
  hasNextImg = true;
  hasPrevImg = false;
  hasMultiImages = true;
  isLoadingImg = false;
  isLoadingNearby = false;
  mapMarkers: IMapMarkerWithCustomData<VisitorPlace | VisitorEvent>[] = [];
  nearByPlaces$: Observable<VisitorPlace[] | null>;
  nearByEvents: VisitorEvent[] = [];
  filteredNearByEvents: VisitorEvent[] | null = null;
  isShowingNearbyEventsOnMap = false;
  isShowingNearbyPlacesOnMap = false;
  settings: IFrontendSettings;

  constructor(
    public activatedRoute: ActivatedRoute,
    public seoService: SeoService,
    public errorAlert: ErrorAlertService,
    public sanitizer: DomSanitizer,
    public placeService: VisitorPlaceService,
    public modalService: ModalService,
    public dialogRef: MatDialogRef<PlaceDetailsBaseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPlaceDetailsModalData,
    public _store: Store<IAppState>
  ) {
    super({activatedRoute});

    this._store.pipe(select(frontendSettingsSelector)).subscribe((settings) => {
      settings && (this.settings = settings);
    });

    this.loadPlace();

    this.dialogRef
      .afterOpened()
      .pipe(delay(60))
      .subscribe(() => {
        this.isOpened = true;
      });
  }

  public get placeNameAddress() {
    if (this.place?.address?.addressComponents) {
      if (!isEmptyArray(this.place.address.addressComponents)) {
        return `${this.place.name}, ${
          this.place.address?.addressComponents.find((x) =>
            x.types.includes('locality')
          )?.short_name
        }, ${
          this.place.address?.addressComponents.find((x) =>
            x.types.includes('administrative_area_level_1')
          )?.short_name
        }`;
      }
    }

    return this.place.name + ', ' + this.place.address.address;
  }

  public get videoTitle() {
    let title = '';
    if (this.place.youtubeLink) {
      title += 'Youtube Video';
    }
    if (this.place._360VideoKey) {
      title += title ? ' & 360° Video' : '360° Video';
    }
    return title;
  }

  public loadPlace() {
    this.isLoading = true;
    if (this.data.placeDetailsId) {
      this.placeService
        .getPlaces(this.hubName, {
          id: this.data.placeDetailsId,
        } as CalendarQueryCommand)
        .subscribe((places) => {
          if (isEmptyArray(places)) {
            this.errorAlert.showErrorAlert(
              "Uh-oh! Looks like we haven't found this place! Please view other places in the list!"
            );
            this.dialogRef.close();
            return;
          }

          this.place = places.first();
          if (!this.place.isManuallyCreated) {
            this.getPlaceDetails();
          } else {
            this.processPlace();
            this.isLoading = false;
          }
        });
    } else {
      this.place = Object.assign({}, this.data.currentPlace);
      if (!this.place.isManuallyCreated) {
        this.getPlaceDetails();
      } else {
        // this.setMap();
        // this.setCurrentImg();
        // this.setNameIdAndSeo();
        this.processPlace();
        this.isLoading = false;
      }
    }
  }

  public getDirection(place: VisitorPlace) {
    let address = place.address?.address || place.address;
    let url = ConfigService.config.google.googleMapGetDirection.replace(
      '${address}',
      encodeURIComponent(address.toString())
    );
    window.open(url, '_blank');
  }

  public get hasWebsite() {
    return this.place?.website || this.place?.hostLink;
  }

  public visitWebsite() {
    window.open(this.place.website || this.place.hostLink, '_blank');
  }

  public get hasFacebookLink() {
    return this.place?.facebookLink;
  }

  public visitFacebookPage() {
    window.open(this.place.facebookLink, '_blank');
  }

  public showLink() {
    this.copyToClip(window.location.href);
    alert(
      `The following text will be copied into your clipboard:\n\n ${window.location.href}`
    );
  }

  // Youtube video link looks like:
  // https://www.youtube.com/watch?v=puKsjFRwIyw
  // https://www.youtube.com/watch?v=puKsjFRwIyw&t=27s
  public getYoutubeLink() {
    return this.placeService.getYoutubeLink(this.place);
  }

  public get360VideoLink() {
    return this.placeService.get360VideoLink(this.place);
  }

  public loadNearbyEvents(range: number) {
    this.isLoadingNearby = true;
    this.placeService.getNearByEvents(this.place, range).subscribe((events) => {
      this.nearByEvents = events;
      this.isLoadingNearby = false;
    });
  }

  public toggleShowNearbyEventsOnMap(status: MatCheckboxChange) {
    if (status.checked) {
      this.isShowingNearbyPlacesOnMap = false;

      const events = this.nearByEvents.filter(
        (x) =>
          !isNullOrUndefined(x.address.lat) && !isNullOrUndefined(x.address.lng)
      );
      const markers = events.map(
        (x) =>
          ({
            position: {lat: x.address.lat, lng: x.address.lng},
            options: {
              icon: 'https://iti-images.s3.amazonaws.com/imgs/event-48.png',
              zIndex: 2,
            },
            data: x,
          } as IMapMarkerWithCustomData<VisitorPlace | VisitorEvent>)
      );

      this.keepPlaceMapMarker();
      this.mapMarkers = [...this.mapMarkers, ...markers];
      this.isShowingNearbyEventsOnMap = true;
    } else {
      this.keepPlaceMapMarker();
      this.isShowingNearbyEventsOnMap = false;
    }
  }

  public imgLoaded($event: Event) {
    this.isLoadingImg = false;
  }

  public nextImg() {
    if (!this.hasNextImg) return;

    const images = this.getPlaceImages();

    if (this.imgIndex < images.length - 1) {
      this.imgIndex++;
      this.isLoadingImg = true;
      this.currentImg = images[this.imgIndex];
      this.hasPrevImg = true;
      if (this.imgIndex === images.length - 1) {
        this.hasNextImg = false;
      }
    }
  }

  public prevImg() {
    if (!this.hasPrevImg) return;

    const images = this.getPlaceImages();

    if (this.imgIndex > 0) {
      this.imgIndex--;
      this.isLoadingImg = true;
      this.currentImg = images[this.imgIndex];
      this.hasNextImg = true;
      if (this.imgIndex === 0) {
        this.hasPrevImg = false;
      }
    }
  }

  private getPlaceImages() {
    if (
      isEmptyArray(this.place.allowedGooglePhotos) &&
      !isEmptyArray(this.place.blockedGooglePhotos)
    ) {
      console.log('user blocked all google images');
      this.hasMultiImages = false;
      return [];
    }

    let images = clone(
      !isEmptyArray(this.place.allowedGooglePhotos)
        ? this.place.allowedGooglePhotos
        : this.place.photos.map((x) => this.getGoogleImgUrl(x.photo_reference))
    );

    // in DXP edit.place.images.modal, the first allowed image will be used as cover (if the original cover is not uploaded by user)
    // this in way the cover image is the same as the first allowed image, so need to remove the duplicated
    if (this.place.cover.source === images[0]) {
      images.shift();
    }

    images = [this.place.cover.source, ...images];
    images = images.slice(
      0,
      this.settings?.customSettings?.placeImagesShownCount || 10
    );
    if (images.length === 1) this.hasMultiImages = false;

    return images;
  }

  private copyToClip(content) {
    let aux = document.createElement('input');
    aux.setAttribute('value', content);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand('copy');
    document.body.removeChild(aux);
  }

  private processPlace() {
    this.setMap();
    this.setCurrentImg();
    this.setNameIdAndSeo();
  }

  private getPlaceDetails() {
    // place.id is the place google id
    this.placeService
      .getGooglePlaceDetails(this.place.id, this.hubName)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((place: VisitorPlace) => {
        // sometimes a Google place might be removed from Google, thus when we getting place details, it may respond "Invalid 'placeid' parameter"
        if (place.isNotExistInSource) {
          this.errorAlert.showErrorAlert(
            "Uh-oh! Looks like we haven't found this place! Please view other places in the list!"
          );
          this.dialogRef.close();
        }
        Object.assign(this.place, place);

        this.checkDoesHaveServes();

        // this.setMap();
        // this.setCurrentImg();
        // this.setNameIdAndSeo();
        this.processPlace();

        this.getPlaceImages();
      });

    const range = 2;
    this.loadNearbyEvents(range);
  }

  private setNameIdAndSeo() {
    if (!this.isScriptSolution) {
      this.seoService.addNameIdToUrlForNonScriptSolution(
        this.place,
        this.hubName,
        this.dialogRef
      );
    } else {
      // for clients who use the script solution (we embed ImGoing as script into client's website)
      // change the URL by adding the ImGoing event name + Id to the params so that each place has a unique URL
      // when close the details modal, reset the URL to the original URL of the host site
      if (this.seoService.getIfNeedToAddNameIdToUrl()) {
        this.seoService.addNameIdToUrlForScriptSolution(
          this.place,
          this.dialogRef
        );
      }

      this.seoService.createAndInjectGoogleStructureDataScriptForPlace(
        this.place
      );
      this.seoService.addMetaForSEO(this.place.title, this.place.description);
    }
  }

  keepPlaceMapMarker() {
    this.mapMarkers = this.mapMarkers.filter(
      (x) => x.data._id === this.place._id
    );
  }

  private setCurrentImg() {
    this.currentImg = this.place.cover?.source;
  }

  private getGoogleImgUrl(photoReference: string): string {
    return this.placeService.getGoogleImgUrl(
      photoReference,
      this.place.appKey ||
      this.frontendSettings.customSettings.dataSource.googleKey
    );
    // return ConfigService.config.google.placePhoto
    //   .replace('${photo_reference}', photoReference)
    //   .replace(
    //     '${appKey}',
    //     this.place.appKey ||
    //       this.frontendSettings.customSettings.dataSource.googleKey
    //   );
  }

  private checkDoesHaveServes() {
    for (const serve in this.place?.dine) {
      if (Object.prototype.hasOwnProperty.call(this.place?.dine, serve)) {
        const element = this.place?.dine[serve];
        if (element) {
          this.hasServes = true;
          break;
        }
      }
    }

    for (const serve in this.place?.otherServices) {
      if (Object.prototype.hasOwnProperty.call(this.place?.otherServices, serve)) {
        const element = this.place?.otherServices[serve];
        if (element) {
          this.hasOtherServices = true;
          break;
        }
      }
    }

    for (const serve in this.place?.paymentOptions) {
      if (Object.prototype.hasOwnProperty.call(this.place?.paymentOptions, serve)) {
        const element = this.place?.paymentOptions[serve];
        if (element) {
          this.hasPaymentOptions = true;
          break;
        }
      }
    }

    for (const serve in this.place?.parkingOptions) {
      if (Object.prototype.hasOwnProperty.call(this.place?.parkingOptions, serve)) {
        const element = this.place?.parkingOptions[serve];
        if (element) {
          this.hasParkingOptions = true;
          break;
        }
      }
    }

    for (const serve in this.place?.accessibilityOptions) {
      if (Object.prototype.hasOwnProperty.call(this.place?.accessibilityOptions, serve)) {
        const element = this.place?.accessibilityOptions[serve];
        if (element) {
          this.hasAccessibilityOptions = true;
          break;
        }
      }
    }
  }

  private setMap() {
    this.mapMarkers = [
      {
        position: {
          lat: this.place?.lat,
          lng: this.place?.lng,
        },
        data: this.place,
      },
    ] as IMapMarkerWithCustomData<VisitorPlace | VisitorEvent>[];
  }
}
